.field {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
}

.hideMargin {
  margin-bottom: 0;
}

.fieldCheckbox {
  margin: 0;
}

.error {
  box-sizing: border-box;
  height: 20px;
  color: red;

  font-family: "Muller Narrow Light";
  font-size: 12px;
  line-height: 12px;
  font-weight: 300;
  opacity: 0.7;
  padding-top: 5px;
}

.hideError {
  height: 0;
}

.errorCheckbox {
  height: 0;
  padding: 0;
  margin: 0;
}

.labelCheckbox {
  height: 0;
}
