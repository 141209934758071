.tableWrapper {
  margin-top: 80px;
}

p {
  text-align: center;
}

.bold {
  font-family: "Muller Extra Bold";
}

.innerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.baseStyle {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-width: 2;
  border-radius: 2;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
};

.row {
  display: flex;
  justify-content: space-around;
  margin: 16px;
}

.row:first-child {
  margin-top: 30px;
}

.row:last-child {
  margin-bottom: 30px;
}

.downloadButton {
  text-decoration: none;
  color: black;
  display: flex;
  justify-content: center;
}

.downloadButton:hover {
  opacity: 0.5;
}

.form {
  display: flex;
  flex-direction: column;
}

.button {
  cursor: pointer;
  display: block;
  font-family: "Muller Extra Bold";
  margin: 16px 40px 0 auto;
  height: 36px;
  width: 240px;
  background-color: transparent;
  border: 2px solid gray;
}

.button:hover {
  text-decoration: underline;
}

.column {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (max-width: 1440px) {
  .tableWrapper {
    margin-top: 40px;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .tableWrapper {
    margin-top: 0;
  }
}
