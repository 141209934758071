.page {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.paragraph {
  margin: 40px 0 70px 0;
}

.text {
  margin-top: 30px;
  width: 250px;
}

@media (max-width: 767px) {
  .page {
    justify-content: flex-start;
    align-items: center;
    padding: 60px 20px 20px 20px;
    height: auto;
  }
}

@media (max-width: 350px) {
  .page {
    padding: 60px 20px 20px 20px;
  }
}

@media (max-width: 991px) and (max-height: 420px) {
  .page {
    height: auto;
  }
}
